<template>
  <div class="container">
    <div class="nav">
      <div class="nav_title">请假申请</div>
      <div class="nav_left_btn" @click="toHome">
        <img :src="cancel" alt="" width="15" height="25">
      </div>
    </div>
    <div class="content">
      <van-form @submit="onSubmit">
        <van-cell-group inset>
          <van-field
            readonly
            clickable
            name="calendar"
            :value="form.startTime"
            label="开始时间"
            :rules="[{ required: true }]"
            placeholder="点击选择开始时间"
            @click="showStartCalendar = true"
          />
          <van-calendar v-model="showStartCalendar" @confirm="(e) => onCalendarConfirm(e, 'start')" />
          <van-field
            readonly
            clickable
            name="calendar"
            :value="form.endTime"
            label="结束时间"
            :rules="[{ required: true }]"
            placeholder="点击选择结束时间"
            @click="showEndCalendar = true"
          />
          <van-calendar v-model="showEndCalendar" :min-date="endMinDate" @confirm="(e) => onCalendarConfirm(e, 'end')" />
          <van-field
            v-model="form.description"
            rows="2"
            autosize
            :rules="[{ required: true }]"
            label="请假理由"
            type="textarea"
            placeholder="请输入请假理由"
            show-word-limit
          />
          <div style="margin: 16px;">
            <van-button round block type="primary" native-type="submit" style="background-color: #ff7b7b;border-color:#ff7b7b">
              提交
            </van-button>
          </div>
        </van-cell-group>
      </van-form>
    </div>
  </div>
</template>

<script>
import cancel from '@/assets/cancel.png'
import { addLeave } from '@/api/leave'
import moment from 'moment'
import { Toast } from 'vant'

const date = new Date()
export default {
  name: 'AskLeave',
  data() {
    return {
      manager: '',
      title: '',
      showStartCalendar: false,
      showEndCalendar: false,
      timeRange: '',
      showPicker: false,
      cancel,
      form: {
        description: '',
        manager: '',
        startTime: '',
        endTime: ''
      },
      startDate: null,
      endDate: null,
      endMinDate: date
    }
  },
  created() {
    const { role, manager } = this.$store.getters
    console.log(role, manager)
    if (role === 1) {
      this.title = '班主任'
    } else {
      this.title = '主管'
    }
    this.form.manager = manager
  },
  methods: {
    onCalendarConfirm(date, type) {
      console.log(date)
      console.log(type)
      if (type === 'start') {
        this.endMinDate = date
        this.startDate = date
        if (this.endDate && date.getTime() > this.endDate.getTime()) {
          this.endDate = null
          this.form.endTime = ''
        }
        this.form.startTime = moment(date).format('YYYY-MM-DD 00:00:00')
        this.showStartCalendar = false
      } else {
        this.endDate = date
        this.form.endTime = moment(date).format('YYYY-MM-DD 23:59:59')
        this.showEndCalendar = false
      }
    },
    test() {
      this.showPicker = true
      console.log(this.showPicker)
    },
    async onSubmit() {
      console.log('form:')
      console.log(this.form)
      const { code, message } = await addLeave(this.form)
      if (code === 0) {
        Toast.success('申请成功！')
        this.$router.push({ name: 'Leave' })
      } else {
        console.log(message)
      }
    },
    toHome() {
      this.$router.go(-1)
    }
  }
}
</script>

<style scoped lang="scss">
  .container {
    .nav {
      z-index: 999;
      position: fixed;
      top: 0;
      width: 100%;
      display: block;
      text-align: center;
      height: 48px;
      color: white;
      overflow: hidden;
      border-bottom: 1px solid rgba(181, 201, 210, 0.6);
      background-color: #ffffff;

      & > .nav_title {
        display: block;
        font-size: 1.1rem;
        overflow: hidden;
        white-space: nowrap;
        line-height: 48px;
        text-overflow: ellipsis;
        color: #607483;
        width: 60%;
        margin: 0 auto;
        background-color: #ffffff;
      }

      & > .nav_left_btn {
        float: left;
        position: absolute;
        border-width: .9rem .5rem .9rem .7rem;
        border-color: transparent;
        background-size: 1.2rem 2rem;
        line-height: 1rem;
        font-size: 1rem;
        color: #607483;
        padding: 0 .3rem 0 .8rem;
        top: 9px;
        left: 0px;
        min-height: 30px;
        display: flex;
        align-items: center;

        & > .back {
          font-size: 1.1rem;
          margin-left: 3px;
        }
      }

      & > .nav_left_btn:active {
        opacity: 0.5;
      }

      & > .nav_right_btn {
        float: right;
        position: absolute;
        border-width: 9px 4px 9px 3px;
        border-color: transparent;
        background: transparent;
        line-height: 1rem;
        font-size: 1rem;
        color: #607483;
        padding: 0 8px 0 2px;
        top: 8px;
        right: 0;
        min-width: 52px;
        min-height: 30px;
        display: flex;
        align-items: center;
      }

      & > .nav_right_btn_click {
        opacity: 0.5;
      }
    }
    .content {
      padding: 4rem 0rem;
    }
  }
  ::v-deep .van-picker__confirm, ::v-deep .van-picker__cancel, ::v-deep .van-field__label, ::v-deep .van-field__control {
    font-size: 16px;
  }
</style>
